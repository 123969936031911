import React from "react"

const MaturityNumber = ({ ausdrucksZahl, lebensZahl }) => {
  let selected
  if (ausdrucksZahl && lebensZahl) {
    const sum = ausdrucksZahl + lebensZahl
    selected = sum % 9 === 0 ? 9 : sum % 9
  }

  const values = [
    [
      { value: 3, active: false },
      { value: 6, active: false },
      { value: 9, active: false },
    ],
    [
      { value: 2, active: false },
      { value: 5, active: false },
      { value: 8, active: false },
    ],
    [
      { value: 1, active: false },
      { value: 4, active: false },
      { value: 7, active: false },
    ],
  ].map((row) => {
    row.map((cell) => {
      if (cell.value === selected) cell.active = true
      return cell
    })
    return row
  })

  return (
    <div>
      <h5>Maturitätszahl: </h5>
      <table
        className="table table-sm table-bordered"
        style={{ textAlign: "center" }}
      >
        <tbody>
          {values.map((row) => (
            <tr key={values.indexOf(row)}>
              {row.map((cell) => (
                <td
                  key={cell.value}
                  className={cell.active ? "bg-primary" : ""}
                >
                  {cell.value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default MaturityNumber
