import React from "react";

const Legend = () => {
  return (
    <table className="table table-sm">
      <tbody>
        <tr>
          <th scope="row">1</th>
          <td>A</td>
          <td>J</td>
          <td>S</td>
        </tr>
        <tr>
          <th scope="row">2</th>
          <td>B</td>
          <td>K</td>
          <td>T</td>
        </tr>
        <tr>
          <th scope="row">3</th>
          <td>C</td>
          <td>L</td>
          <td>U</td>
        </tr>
        <tr>
          <th scope="row">4</th>
          <td>D</td>
          <td>M</td>
          <td>V</td>
        </tr>
        <tr>
          <th scope="row">5</th>
          <td>E</td>
          <td>N</td>
          <td>W</td>
        </tr>
        <tr>
          <th scope="row">6</th>
          <td>F</td>
          <td>O</td>
          <td>X</td>
        </tr>
        <tr>
          <th scope="row">7</th>
          <td>G</td>
          <td>P</td>
          <td>Y</td>
        </tr>
        <tr>
          <th scope="row">8</th>
          <td>H</td>
          <td>Q</td>
          <td>Z</td>
        </tr>
        <tr>
          <th scope="row">9</th>
          <td>I</td>
          <td>R</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default Legend;
