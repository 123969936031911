import React from "react";
import "./gridResult.css";
import formatOutput from "../utils/formatOutput";

const GridResult = (props) => {
  const resultLength = 7;

  var vocaleOutput = formatOutput(props.vocaleValue);
  var konsonsantOutput = formatOutput(props.konsonantValue);
  var allLettersOutput = formatOutput(props.konsonantValue + props.vocaleValue);

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <input
              className="gridResult"
              type="text"
              size={resultLength}
              value={vocaleOutput}
              disabled
            ></input>
          </td>
        </tr>
        <tr>
          <td>
            <input
              className="gridResult"
              type="text"
              size={resultLength}
              value={allLettersOutput}
              disabled
            ></input>
          </td>
        </tr>
        <tr>
          <td>
            <input
              className="gridResult"
              type="text"
              size={resultLength}
              value={konsonsantOutput}
              disabled
            ></input>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default GridResult;
