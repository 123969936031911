import "bootstrap/dist/css/bootstrap.min.css"
import $ from "jquery"
import Popper from "popper.js"
import "bootstrap/dist/js/bootstrap.bundle.min"
import React, { Component } from "react"
import "./App.css"
import Header from "./components/header"
import Legend from "./components/legend"
import GridInput from "./components/gridInput"
import GridResult from "./components/gridResult"
import MaturityNumber from "./components/maturityNumber"
import BirthdayInput from "./components/birthdayInput"
import formatOutput from "./utils/formatOutput"

class App extends Component {
  state = {
    vocaleValues: [],
    konsonantValues: [],
    vocaleSum: 0,
    konsonantSum: 0,
    birthday: "",
    birthdaySum: 0,
  }

  handleNameChanged = (values) => {
    const vocaleValues = [...values.vocaleValue].map((l) => Number(l))
    const konsonantValues = [...values.konsonantValue].map((l) => Number(l))

    const vocaleSum = vocaleValues.reduce((acc, val) => (acc += val), 0)
    const konsonantSum = konsonantValues.reduce((acc, val) => (acc += val), 0)

    const vocaleChecksum = vocaleSum % 9 === 0 ? 9 : vocaleSum % 9
    const konsonantChecksum = konsonantSum % 9 === 0 ? 9 : konsonantSum % 9
    const allValueChecksum =
      (vocaleSum + konsonantSum) % 9 === 0 ? 9 : (vocaleSum + konsonantSum) % 9

    this.setState({
      vocaleValues,
      konsonantValues,
      vocaleSum,
      konsonantSum,
      vocaleChecksum,
      konsonantChecksum,
      allValueChecksum,
    })
  }

  handleBirthdayChanged = (value) => {
    const birthdaySum = [...value]
      .map((l) => Number(l))
      .reduce((acc, val) => (acc += val), 0)

    const birthdayChecksum = birthdaySum % 9 === 0 ? 9 : birthdaySum % 9

    this.setState({ birthday: value, birthdaySum, birthdayChecksum })
  }

  render() {
    return (
      <>
        <Header></Header>
        <div className="chart-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
          <h1>Numerologisches Geburtschart</h1>
          <p className="lead">
            Ausfüllhilfe für das numerologische Geburtschart. In der mittleren
            Zeile ist der Name des Klienten einzutragen. Die Berechnungen
            erfolgen automatisch und in Echtzeit. Können daher jederzeit
            mitverfolgt werden.
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-2">
              <Legend></Legend>
            </div>

            <div className="col-8">
              <h5>Name:</h5>
              <GridInput onValueChanged={this.handleNameChanged}></GridInput>
              <div style={{ marginTop: "30px" }}>
                <p>
                  Summe der Vokale ergibt die{" "}
                  <b>Seelenzahl: {this.state.vocaleChecksum}</b> = 20% der
                  Energie des Chart
                </p>
                <p>
                  Summe der Konsonanten ergibt die{" "}
                  <b>Zahl des inneren Selbst: {this.state.konsonantChecksum}</b>{" "}
                  = 5% der Energie des Chart
                </p>
                <p>
                  Summe aller Buchstaben ergibt die{" "}
                  <b>Ausdruckszahl: {this.state.allValueChecksum}</b>= 25% der
                  Energie des Chart
                </p>
              </div>
            </div>
            <div className="col-2">
              <h5>Ergebnis:</h5>
              <GridResult
                vocaleValue={this.state.vocaleSum}
                konsonantValue={this.state.konsonantSum}
              ></GridResult>
            </div>
          </div>
          <div className="row">
            <div className="col-2"></div>
            <div className="col-8">
              <div className="row">
                <div className="col-6">
                  <BirthdayInput
                    onValueChanged={this.handleBirthdayChanged}
                  ></BirthdayInput>
                </div>
                <div
                  style={{ flexDirection: "column" }}
                  className="d-flex justify-content-end align-items-center col-6"
                >
                  <h3>Lebenszahl:</h3>
                  <div className="bordered-box">
                    {formatOutput(this.state.birthdaySum)}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2">
              <MaturityNumber
                ausdrucksZahl={this.state.allValueChecksum}
                lebensZahl={this.state.birthdayChecksum}
              ></MaturityNumber>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default App
