import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <nav className="navbar navbar-light bg-light">
      <a className="navbar-brand" href="/">
        <FontAwesomeIcon icon={faBalanceScale}></FontAwesomeIcon>
        <span className="m-2">Geburtschart</span>
      </a>
    </nav>
  );
};

export default Header;
