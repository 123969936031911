import React, { Component } from "react"
import { vocale } from "../utils/vocale"
import { digitMap } from "../utils/digitMap"
import "./gridInput.css"

class GridInput extends Component {
  state = { maxLength: 30, vocaleRow: "", konsonantRow: "" }

  handleChange = (event) => {
    const vocaleValue = [...event.target.value.toLowerCase()]
      .map((l) => {
        if (!vocale.includes(l)) return " "
        const val = digitMap.get(l)
        return val ? val : " "
      })
      .reduce((acc, val) => {
        return (acc += val)
      }, "")

    const konsonantValue = [...event.target.value.toLowerCase()]
      .map((l) => {
        if (vocale.includes(l)) return " "
        const val = digitMap.get(l)
        return val ? val : " "
      })
      .reduce((acc, val) => {
        return (acc += val)
      }, "")

    this.setState({
      vocaleRow: vocaleValue,
      konsonantRow: konsonantValue,
    })

    this.props.onValueChanged({
      value: event.target.value,
      vocaleValue: vocaleValue,
      konsonantValue: konsonantValue,
    })
  }

  render() {
    return (
      <table>
        <tbody>
          <tr>
            <td>
              <input
                className="gridText"
                type="text"
                size={this.state.maxLength}
                maxLength={this.state.maxLength}
                value={this.state.vocaleRow}
                disabled
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <input
                className="gridText"
                type="text"
                size={this.state.maxLength}
                maxLength={this.state.maxLength}
                onChange={this.handleChange}
              ></input>
            </td>
          </tr>
          <tr>
            <td>
              <input
                className="gridText"
                type="text"
                size={this.state.maxLength}
                maxLength={this.state.maxLength}
                value={this.state.konsonantRow}
                disabled
              ></input>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}

export default GridInput
