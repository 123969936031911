function formatOutput(originalValue) {
  if (!originalValue || originalValue === 0) return "";

  if (originalValue < 10) return "" + originalValue;

  const checksum = [...(originalValue + "")].reduce(
    (acc, val) => (acc += Number(val)),
    0
  );

  if (checksum < 10) return `${originalValue}/${checksum}`;

  const secondChecksum = [...(checksum + "")].reduce(
    (acc, val) => (acc += Number(val)),
    0
  );

  return `${originalValue}/${checksum}/${secondChecksum}`;
}

export default formatOutput;
