import React from "react";
import "./birthdayInput.css";

const BirthdayInput = (props) => {
  const handleValueChange = (event) => {
    props.onValueChanged(event.target.value);
  };

  return (
    <>
      <input
        className="birthdayTTMMJJJJ"
        type="text"
        size="8"
        maxLength="8"
        value="TTMMJJJJ"
        disabled
      />

      <input
        onChange={handleValueChange}
        className="birthdayInput"
        type="text"
        size="8"
        maxLength="8"
      />
    </>
  );
};

export default BirthdayInput;
