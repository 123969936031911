export const digitMap = new Map()

digitMap.set("a", 1)
digitMap.set("ä", 1)
digitMap.set("b", 2)
digitMap.set("c", 3)
digitMap.set("d", 4)
digitMap.set("e", 5)
digitMap.set("f", 6)
digitMap.set("g", 7)
digitMap.set("h", 8)
digitMap.set("i", 9)
digitMap.set("j", 1)
digitMap.set("k", 2)
digitMap.set("l", 3)
digitMap.set("m", 4)
digitMap.set("n", 5)
digitMap.set("o", 6)
digitMap.set("ö", 6)
digitMap.set("p", 7)
digitMap.set("q", 8)
digitMap.set("r", 9)
digitMap.set("s", 1)
digitMap.set("t", 2)
digitMap.set("u", 3)
digitMap.set("ü", 3)
digitMap.set("v", 4)
digitMap.set("w", 5)
digitMap.set("x", 6)
digitMap.set("y", 7)
digitMap.set("z", 8)
